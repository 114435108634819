<template>
  <div>
    <div v-if="client">
      <dashboard-payments
        v-if="
          G_PROGRAMS_IN_NEW_PAYMENT.includes(programId) ||
          G_PROGRAMS_IN_NEW_PAYMENT.includes(client.program_id)
        "
        :client-account-id="$route.params.idClient"
        :key="keyPaymentSchedule"
      ></dashboard-payments>

      <b-card v-else :style="isDarkSkin ? 'background-color: #232328;' : ''">
        <b-row class="mt-3">
          <b-col cols="3">
            <b-card
              :class="isDarkSkin ? 'border-card-dark' : 'border-card-light'"
            >
              <b-row>
                <b-col cols="4">
                  <div
                    style="background-color: #0090e7; border-radius: 5px"
                    class="d-flex align-items-center justify-content-center py-1"
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="30"
                      :style="isDarkSkin ? 'color: black' : 'color: white'"
                    />
                  </div>
                </b-col>
                <b-col cols="8">
                  <div class="">TOTAL FEES AND CHARGES</div>
                  <div
                    style="font-weight: bold; padding-top: 10px"
                    :class="client.total_charge < 0 ? 'text-danger' : ''"
                  >
                    {{ client.total_charge | formatMoney }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card
              :class="isDarkSkin ? 'border-card-dark' : 'border-card-light'"
            >
              <b-row>
                <b-col cols="4">
                  <div
                    style="background-color: #ff9900; border-radius: 5px"
                    class="d-flex align-items-center justify-content-center py-1"
                  >
                    <feather-icon
                      icon="CreditCardIcon"
                      size="30"
                      :style="isDarkSkin ? 'color: black' : 'color: white'"
                    />
                  </div>
                </b-col>
                <b-col cols="8">
                  <div class="">TOTAL PAYMENTS</div>
                  <div
                    style="font-weight: bold; padding-top: 10px"
                    :class="client.total_payments < 0 ? 'text-danger' : ''"
                  >
                    {{ client.total_payments | formatMoney }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card
              :class="isDarkSkin ? 'border-card-dark' : 'border-card-light'"
            >
              <b-row>
                <b-col cols="4">
                  <div
                    style="background-color: #00d25b; border-radius: 5px"
                    class="d-flex align-items-center justify-content-center py-1"
                  >
                    <tabler-icon
                      icon="ScaleIcon"
                      size="30"
                      :style="isDarkSkin ? 'color: black' : 'color: white'"
                    />
                  </div>
                </b-col>
                <b-col cols="8">
                  <div class="">ACCOUNT BALANCE</div>
                  <div
                    style="font-weight: bold; padding-top: 10px"
                    :class="client.balance < 0 ? 'text-danger' : ''"
                  >
                    {{ client.balance | formatMoney }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card
              :class="
                isDarkSkin
                  ? 'border-card-dark cursor-pointer'
                  : 'border-card-light cursor-pointer'
              "
              @click="openExpensesModal()"
            >
              <b-row>
                <b-col cols="4">
                  <div
                    style="background-color: #d7ee15; border-radius: 5px"
                    class="d-flex align-items-center justify-content-center py-1"
                  >
                    <tabler-icon
                      icon="CashIcon"
                      size="30"
                      :style="isDarkSkin ? 'color: black' : 'color: white'"
                    />
                  </div>
                </b-col>
                <b-col cols="8" class="pr-3">
                  <div class="d-flex justify-content-between">
                    <div class="">
                      EXPENSES
                      <b-badge v-if="total_general_expenses > 0" pill variant="danger">
                        {{ total_general_expenses }}
                      </b-badge>
                    </div>
                    <div
                      class="text-danger"
                      style="font-weight: bold; margin-left: 10px"
                    >
                      {{ client.total_expenses | formatMoney }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mt-1">
                    <div class="">PROFIT</div>
                    <div
                      id="profit_value"
                      :class="
                        clientProfit < 0
                          ? 'text-danger'
                          : clientProfit > 0
                          ? 'text-success'
                          : ''
                      "
                      style="font-weight: bold; margin-left: 10px"
                    >
                      {{ clientProfit | formatMoney }}
                    </div>
                    <b-tooltip
                      triggers="hover"
                      target="profit_value"
                      placement="top"
                    >
                      <div style="font-weight: bolder">
                        <span class="text-success">TOTAL PAYMENTS</span> -
                        <span class="text-danger">TOTAL EXPENSES</span>
                        <br />
                        <span class="text-success">{{
                          client.total_payments | formatMoney
                        }}</span>
                        -
                        <span class="text-danger">{{
                          client.total_expenses | formatMoney
                        }}</span>
                      </div>
                    </b-tooltip>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <div
              :class="
                isDarkSkin
                  ? 'border-card-dark p-1 card-bg-dark'
                  : 'border-card-light p-1'
              "
            >
              <div :style="isDarkSkin ? 'color: white' : 'color: black'">
                <strong>Fee and Charges</strong>
                <hr style="border-top: 1px solid" />
              </div>
              <b-table
                ref="listCharges"
                small
                responsive
                :fields="visibleFields1"
                :items="searchListCharges"
                show-empty
                sticky-header="50vh"
                :busy.sync="isBusy"
                class="table-new-customization custom-table font-small-3 text-justify blue-scrollbar"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template v-slot:cell(date)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.created_at | myGlobal }}
                  </p>
                </template>
                <template v-slot:cell(fee_or_charge)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.fee }}
                    <space v-if="data.item.current_creditor || data.item.creditor_name"
                      >({{ data.item.current_creditor || data.item.creditor_name }}
                      <span v-if="data.item.current_creditor_account_number"
                        >- #.
                        {{
                          data.item.current_creditor_account_number.substring(
                            data.item.current_creditor_account_number.length - 4
                          )
                        }} </span
                      >
                      <span v-else
                        >- #.
                        {{
                          data.item.account.substring(
                            data.item.account.length - 4
                          )
                        }} </span
                      >)
                    </space>
                  </p>
                </template>
                <template v-slot:cell(amount)="data">
                  <div class="d-flex align-items-center">
                    <span style="width: 70px" class="mb-0 font-weight-bold">
                      $ {{ data.item.amount }}
                    </span>
                    <feather-icon
                      v-if="
                        data.item.state_charge == 0 && data.item.from_other != 1
                      "
                      class="ml-1 cursor-pointer"
                      icon="DollarSignIcon"
                      size="16"
                      @click="paymentCharge(data.item)"
                    />
                    <feather-icon
                      v-if="data.item.state_charge == 0 && data.item.from_other"
                      v-b-tooltip.hover.top="'Waiting for other payment'"
                      class="ml-1 text-warning"
                      icon="ClockIcon"
                      size="16"
                    />
                  </div>
                </template>
                <template
                  v-if="
                    isSupervisor === true ||
                    isCeo === true ||
                    moduleId === 5 ||
                    isChief
                  "
                  v-slot:cell(actions)="data"
                >
                  <div>
                    <feather-icon
                      v-if="data.item.t_charge != null"
                      icon="EditIcon"
                      size="16"
                      class="text-warning"
                      :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                      @click="
                        isResponsibleAccount ? '' : changeCharge(data.item)
                      "
                    />
                  </div>
                </template>
              </b-table>
              <b-row class="mb-1 mt-1">
                <b-col>
                  <b-button
                    variant="primary"
                    :disabled="isResponsibleAccount"
                    @click="openChargeModal(null)"
                    class="button-style"
                  >
                    <feather-icon icon="PlusIcon" size="15" />
                    ADD CHARGE
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col lg="8">
            <div
              :class="
                isDarkSkin
                  ? 'border-card-dark p-1 card-bg-dark'
                  : 'border-card-light p-1'
              "
            >
              <div :style="isDarkSkin ? 'color: white' : 'color: black'">
                <strong>Customer Payments</strong>
                <hr style="border-top: 1px solid" />
              </div>
              <b-table
                slot="table"
                ref="listPayments"
                small
                show-empty
                sticky-header="50vh"
                :busy.sync="isBusy"
                class="table-new-customization custom-table font-small-3 justify-content-start blue-scrollbar"
                :fields="visibleFields2"
                :items="searchListPayments"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template v-slot:cell(date)="data">
                  <div class="d-flex justify-content-start">
                    <p style="width: 100px" class="mb-0 font-weight-bold mr-1">
                      {{ data.item.settlement_date | myGlobal }}
                    </p>
                    <feather-icon
                      :id="`credit_${data.index}_information`"
                      v-if="data.item.type_transaction_id === 8"
                      v-b-tooltip.hover.bottom
                      icon="EyeIcon"
                      class="cursor-pointer ml-1 text-primary"
                      size="18"
                    />
                    <b-tooltip
                      :target="`credit_${data.index}_information`"
                      variant="primary"
                      placement="bottom"
                      >Credit Created by:

                      {{ data.item.user_creator }}
                      <br />
                      Credit Created at:
                      <br />
                      {{ data.item.created_at }}
                      <br />
                      Reason of Credit:
                      <br />
                      {{ data.item.comment }}</b-tooltip
                    >
                    <feather-icon
                      v-if="
                        data.item.status_transaction_id != 1 &&
                        data.item.amount == 0 &&
                        data.item.type_transaction_id != 10 &&
                        data.item.type_transaction_id != 11
                      "
                      class="text-danger cursor-pointer"
                      size="18"
                      icon="XCircleIcon"
                      @click="deleteWithouPayment(data.item.t_id)"
                    />
                  </div>
                </template>
                <template v-slot:cell(type)="data">
                  <p
                    class="mb-0 font-weight-bold mr-1"
                    :style="
                      data.item.type_transaction_id == 14 &&
                      data.item.status_transaction_id != 1
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{ data.item.type }}
                  </p></template
                >
                <template v-slot:cell(transaction_id)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.transaction_id }}
                  </p>
                </template>
                <template v-slot:cell(ref_transaction)="data">
                  {{ data.item.ref_transaction }}
                  <div
                    v-if="
                      data.item.pending_void_transactions > 0 &&
                      ![10, 11].includes(data.item.type_transaction_id)
                    "
                  >
                    <b-badge
                      variant="warning"
                      style="cursor: pointer"
                      class="badge"
                    >
                      <tabler-icon
                        icon="ClockIcon"
                        size="18"
                        class="pending-void-transaction"
                      />
                      Pending Partial Void</b-badge
                    >
                  </div>
                </template>

                <template v-slot:cell(amount)="data">
                  <span
                    v-if="
                      ![10, 11, 15, 16, 17].includes(
                        data.item.type_transaction_id
                      )
                    "
                    >$ {{ data.item.amount }}</span
                  >
                  <span
                    v-if="
                      ![10, 11, 15, 16, 17].includes(
                        data.item.type_transaction_id
                      ) && parseInt(data.item.sumref) > 0
                    "
                    class="text-info ml-1"
                    >($
                    {{
                      (data.item.amount - data.item.sumref).toFixed(2)
                    }})</span
                  >
                  <span
                    v-if="[16, 17].includes(data.item.type_transaction_id)"
                    class="text-danger"
                  >
                    -${{ data.item.amount }}
                  </span>
                  <span
                    v-if="
                      data.item.type_transaction_id === 10 ||
                      data.item.type_transaction_id === 11
                    "
                    >$ ({{ data.item.amount }})</span
                  >
                  <span
                    v-if="validateTypeTransactionChargeBack(data)"
                    style="color: red"
                    >$ {{ "-" + data.item.amount }}</span
                  >
                  <span
                    v-if="validateTypeTransactionChargeBackReturn(data)"
                    style="color: green"
                    >$ {{ data.item.amount }}</span
                  >
                </template>
                <template v-slot:cell(method)="data">
                  <div class="d-flex justify-content-start">
                    <span
                      style="min-width: 100px"
                      class="mb-0 font-weight-bold"
                    >
                      {{ data.item.method_payment }}
                    </span>
                    <div>
                      <feather-icon
                        v-if="[10, 11].includes(data.item.type_transaction_id)"
                        :id="`EyeIcon-${data.index}`"
                        v-b-tooltip.hover.bottom
                        icon="EyeIcon"
                        class="text-primary cursor-pointer"
                        size="18"
                        title="SHOW"
                        @click="
                          openVoidRefundInfoModal(data.item.transaction_id)
                        "
                      />
                    </div>
                    <tabler-icon
                      v-if="
                        data.item.type_transaction_id != 10 &&
                        data.item.type_transaction_id != 11 &&
                        data.item.void == 1 &&
                        data.item.w_card == 1 &&
                        (roleId == 1 || moduleId == 4) &&
                        data.item.amount != 0 &&
                        data.item.status_id != 4 &&
                        data.item.pending_void_transactions == 0 &&
                        parseFloat(data.item.sumref) <
                          parseFloat(data.item.amount)
                      "
                      v-b-tooltip.hover.bottom
                      size="18"
                      class="text-danger cursor-pointer"
                      icon="CoinIcon"
                      title="Void"
                      @click="openVoidRefundModal(data.item, 1)"
                    />

                    <feather-icon
                      v-if="
                        data.item.type_transaction_id != 10 &&
                        data.item.type_transaction_id != 11 &&
                        data.item.void == 0 &&
                        data.item.refund == 1 &&
                        data.item.w_card == 1 &&
                        (roleId == 1 || moduleId == 4) &&
                        data.item.amount != 0 &&
                        data.item.status_id != 4 &&
                        data.item.pending_void_transactions == 0 &&
                        parseFloat(data.item.sumref) <
                          parseFloat(data.item.amount)
                      "
                      v-b-tooltip.hover.bottom
                      icon="ClockIcon"
                      class="text-success cursor-pointer"
                      size="18"
                      title="Refund"
                      @click="openVoidRefundModal(data.item, 2)"
                    />
                  </div>
                </template>
                <template v-slot:cell(status)="data">
                  <div
                    :style="`color: ${data.item.color}; cursor: ${
                      data.item.status_id != 4 && data.item.status_id != 19
                        ? `auto`
                        : `pointer`
                    }`"
                    @click="
                      data.item.status_id != 4 && data.item.status_id != 19
                        ? ``
                        : ApproveOrDeclineHeldTransaction(data.item)
                    "
                  >
                    <feather-icon
                      v-if="
                        data.item.status_id == 4 || data.item.status_id == 19
                      "
                      icon="RefreshCcwIcon"
                      style="margin-right: 0.1rem"
                      size="15"
                    />
                    <b-badge
                      v-if="data.item.status_id == 19"
                      variant="light-warning"
                    >
                      Held for review
                    </b-badge>
                    <b-badge
                      v-else-if="
                        [6, 14].includes(data.item.type_transaction_id) &&
                        data.item.status_id == 3
                      "
                      variant="light-warning"
                    >
                      Pending
                    </b-badge>
                    <b-badge
                      v-else-if="
                        ['Approved', 'Settled Successfully'].includes(
                          data.item.status
                        )
                      "
                      :variant="
                        data.item.status == 'Approved'
                          ? 'light-success'
                          : data.item.status == 'Settled Successfully'
                          ? 'light-warning'
                          : 'light-primary'
                      "
                    >
                      {{ data.item.status }}
                    </b-badge>
                    <b-badge v-else variant="light-primary">
                      {{ data.item.status }}
                    </b-badge>
                  </div>
                </template>
              </b-table>
              <b-row class="mt-1">
                <b-col v-if="moduleId == 4">
                  <b-button
                    v-if="!agentConnection"
                    variant="primary"
                    class="d-flex align-items-center button-style"
                    @click="openAddCreditModal"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      style="margin-right: 0.5rem"
                      size="15"
                    />
                    ADD CREDIT
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    variant="primary"
                    class="d-flex align-items-center button-style"
                    :disabled="isResponsibleAccount"
                    @click="openCreditCardInfoModal"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      style="margin-right: 0.5rem"
                      size="15"
                    />
                    CREDIT CARD
                    <span
                      v-if="countData > 0"
                      class="badge rounded-pill bg-danger small"
                      style="font-size: 90% !important; margin-left: 0.5rem"
                    >
                      {{ countData }}</span
                    >
                  </b-button>
                </b-col>
                <b-col></b-col>
                <b-col
                  v-if="
                    ((client.balance != '0.00' &&
                      Number(client.balance) <= 0) ||
                      [5, 7, 18].some((module) => module == moduleId)) &&
                    moduleId !== 6 &&
                    moduleId !== 22
                  "
                >
                  <b-button
                    v-if="!agentConnection"
                    :disabled="
                      ((roleId == 3 && client.paid == 0) ||
                      (roleId != 3 && client.paid < 2)
                        ? false
                        : true) || isResponsibleAccount
                    "
                    variant="primary"
                    @click="startMonthlyPaymentProcess"
                    class="button-style"
                  >
                    <feather-icon icon="PlusIcon" size="15" />
                    MONTHLY PAYMENT
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <!-- <b-col lg="6" class="mt-1">
            <div
              :class="
                isDarkSkin
                  ? 'border-card-dark p-1 card-bg-dark'
                  : 'border-card-light p-1'
              "
            >
              <div :style="isDarkSkin ? 'color: white' : 'color: black'">
                <strong>Expenses</strong>
                <hr style="border-top: 1px solid" />
              </div>
                <b-tabs
                  active-tab-class="p-0 "
                  pills
                  nav-class="mb-0 mt-1"
                  active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
                >
                  <b-tab title="Credit Card">
                    <credit-card-expenses :caId="$route.params.idClient"/>
                  </b-tab>
                </b-tabs>
            </div>
          </b-col> -->
        </b-row>
      </b-card>
      <add-credit-modal
        v-if="addCreditOn"
        :client="client"
        @refresh="refresh"
        @close="closeAddCreditModal"
      />
      <add-charge-modal
        v-if="addChargeOn"
        :title-add-charge="titleAddCharge"
        :edit="changeChargeId"
        @refresh="refresh"
        @close="closeChargeModal"
      />

      <credit-card-modal
        v-if="creditCardOn"
        :client="client"
        @close="closeCreditCardInfoModal"
      />

      <zero-payment-modal
        v-if="zeroPaymentOn"
        :zero-payment-transaction-id="zeroPaymentTransactionId"
        @refresh="refresh"
        @close="closeZeroPaymentModal"
      />
      <monthly-payment-modal
        v-if="monthlyPaymentOn"
        @refreshTable="refresh"
        @close="closeMonthlyPaymentModal"
        @paymentSaveSuccessfully="
          connectionProcessWhenHoldPayMonthlyPayment($event)
        "
        @refreshPaymentScheduleGrid="refreshPaymentSchedule"
      />

      <void-refund-modal
        v-if="voidRefundModalOn"
        :void-refund-data="voidRefundData"
        :type-void-refund="typeVoidRefund"
        @refresh="refresh"
        @close="closeVoidRefundModal"
      />

      <void-refund-info
        v-if="voidrefundInfoModalOn"
        :transaction-id="transactionId"
        @close="closeVoidRefundInfoModal"
      />
      <payment-charge-modal
        v-if="paymentChargeOn"
        :data-charge="dataCharge"
        :title-add-charge="titleAddCharge"
        @refresh="refresh"
        @close="closePaymentChargeModal"
      />
      <update-payment-modal
        v-if="updatePaymentModalOn"
        :transaction-id="transactionId"
        :client="client"
        @refresh="refresh"
        @close="closeUpdatePaymentModal"
      />
      <modal-approve-supervisor
        v-if="modalApproveSupervisorOn"
        :modal-approve-supervisor="modalApproveSupervisorOn"
        :from-bc-pay="true"
        @approveSup="updatePaymentModalOn = true"
        @closeModalApprove="modalApproveSupervisorOn = false"
      />
      <new-relationship-modal
        v-if="activeNewRelationshipModal"
        :active="activeNewRelationshipModal"
        :pre-selected-agent-id="preSelectedAgentId"
        @close="closeNewRelationshipModal($event)"
      />
      <expenses-modal
        v-if="showExpensesModal"
        @close="showExpensesModal = false"
      />
    </div>
    <b-spinner v-else class="align-middle mr-1" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/fields1.data";
import dataFields2 from "@/views/commons/components/clients/dashboard/options/pay-module/fields2.data";

import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import AddChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import CreditCardModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/credit-card-modal/CreditCardModal.vue";
import ZeroPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/ZeroPaymentModal.vue";
import MonthlyPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/MonthlyPaymentModal.vue";
import VoidRefundModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundModal.vue";
import VoidRefundInfo from "@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundInfoModal.vue";
import PaymentChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/PaymentChargeModal.vue";
import UpdatePaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/UpdatePaymentModal.vue";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import ConnectionUsersRelationshipService from "@/views/ce-digital/sub-modules/settings/views/users-relationship/service/users-relationship-connecion.service";
import NewRelationshipModal from "@/views/ce-digital/sub-modules/settings/views/users-relationship/components/NewRelationshipModal.vue";
import DashboardService from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/service/dashborard.service.js";
import AddCreditorDsModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/AddCreditorDsModal.vue";
import AddCreditModal from "@/views/administration/views/clients/modals/AddCreditModal.vue";
import moment from "moment";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";
import CreditCardExpenses from "@/views/commons/components/clients/dashboard/options/pay-module/components/CreditCardExpenses.vue";
import ExpensesModal from "@/views/ceo/dashboard/modals/expenses/ExpensesModal.vue";
// only CE
import DashboardPayments from "@/views/commons/components/clients/dashboard/options/pay-module/components/DashboardPayments.vue";
export default {
  components: {
    AddCreditorDsModal,
    AddChargeModal,
    CreditCardModal,
    ZeroPaymentModal,
    MonthlyPaymentModal,
    VoidRefundModal,
    VoidRefundInfo,
    PaymentChargeModal,
    UpdatePaymentModal,
    ModalApproveSupervisor,
    NewRelationshipModal,
    AddCreditModal,
    DashboardPayments,
    CreditCardExpenses,
    ExpensesModal,
  },

  data() {
    return {
      activeNewRelationshipModal: false,
      updatePaymentModalOn: false,
      voidRefundModalOn: false,
      voidrefundInfoModalOn: false,
      modalApproveSupervisorOn: false,
      isBusy: false,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      addChargeOn: false,
      fields1: dataFields,
      fields2: dataFields2,
      listcharges: [],
      listpayments: [],
      finishedLoading: true,
      creditCardOn: false,
      zeroPaymentOn: false,
      monthlyPaymentOn: false,
      changeChargeId: null,
      titleAddCharge: "",
      transactionId: null,
      zeroPaymentTransactionId: null,
      dataCharge: null,
      paymentChargeOn: null,
      preSelectedAgentId: null,
      typeVoidRefund: null,
      voidRefundData: {},
      addCreditOn: false,
      keyPaymentSchedule: Math.random(),
      showExpensesModal: false,
    };
  },

  watch: {
    async client(newVal) {
      if (newVal) {
        await Promise.all([
          this.$store.dispatch("DebtSolutionClients/A_COUNT_CREDIT_CARD", {
            id: this.client.lead_id,
          }),
        ]);
      }
    },
    async getPaymentsTableStatus(newVal) {
      if (newVal) {
        this.refreshTable();
        this.setPaymentsTableStatus(false);
      }
    },
    RELOAD_TRANSACTIONS_STATUS() {
      this.refresh();
      this.closeChargeModal();
      this.refreshPaymentSchedule();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countData: "DebtSolutionClients/G_COUNT_CREDIT_CARD",
      getPaymentsTableStatus: "DebtSolutionClients/G_UPDATE_PAYMENTS_TABLE",
      RELOAD_TRANSACTIONS_STATUS: "TransactionStatusStore/G_REFRESH",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
      total_expenses: "ExpensesStore/G_TOTAL_EXPENSES_CLIENT",
      pending_expenses: "ExpensesStore/G_PENDING_EXPENSES_CLIENT",
      confirmed_expenses: "ExpensesStore/G_CONFIRMED_EXPENSES_CLIENT",
      total_general_expenses: "ExpensesStore/G_TOTAL_GENERAL_EXPENSES_CLIENT",
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
    roleId() {
      return this.currentUser.role_id;
    },
    modul_id() {
      return this.currentUser.modul_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields1() {
      return this.fields1.filter((field) => field.visible);
    },
    visibleFields2() {
      return this.fields2.filter((field) => field.visible);
    },
    notSpecialist() {
      return this.moduleId !== 11 && this.moduleId !== 25;
    },
    isNotAdministration() {
      return this.moduleId != 4;
    },
    currentRoleId() {
      return this.currentUser.role_id;
    },
    isCeo() {
      return this.currentRoleId === 1;
    },
    isSupervisorOrChief() {
      return this.currentRoleId === 2 || this.currentRoleId === 17;
    },
    ncrAndMonthlyPaymentComissionUsers() {
      return !this.isCeo && !this.isSupervisorOrChief;
    },
    agentConnection() {
      return this.moduleId == 22 && [14, 15].some((rol) => rol == this.roleId);
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    clientProfit() {
      if (this.programId == 2) {
        return this.client.total_payments - this.client.total_expenses;
      }
      return this.client.profit;
    },
  },
  async created() {
    this.isResponsible();
    await Promise.all([
      this.$store.dispatch("DebtSolutionClients/A_COUNT_CREDIT_CARD", {
        id: this.client.lead_id,
      }),
    ]);
    await this.A_COUNT_EXPENSES_BY_CLIENT(this.$route.params.idClient);
    await this.A_TOTAL_EXPENSES_BY_CLIENT(this.$route.params.idClient);
    this.fields1[3].visible = this.notSpecialist;
    this.fields2[5].visible = this.notSpecialist && this.isNotAdministration;
  },

  methods: {
    ...mapActions({
      A_COUNT_EXPENSES_BY_CLIENT: "ExpensesStore/A_COUNT_EXPENSES_BY_CLIENT",
      A_TOTAL_EXPENSES_BY_CLIENT: "ExpensesStore/A_TOTAL_EXPENSES_BY_CLIENT",
    }),
    creditorName(item){
      return item.current_creditor ? item.current_creditor : item.creditor_name
    },
    openExpensesModal() {
      this.showExpensesModal = true;
    },
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
        account_id: this.$route.params.idClient,
      });
    },

    refreshTable() {
      this.$refs.listCharges.refresh();
    },

    openAddCreditModal() {
      this.addCreditOn = true;
    },

    closeAddCreditModal() {
      this.addCreditOn = false;
    },

    openCreditCardInfoModal() {
      this.creditCardOn = true;
    },

    closeCreditCardInfoModal() {
      this.creditCardOn = false;
    },

    openVoidRefundInfoModal(transactionId) {
      this.transactionId = transactionId;
      this.voidrefundInfoModalOn = true;
    },
    closeVoidRefundInfoModal() {
      this.voidrefundInfoModalOn = false;
    },
    openVoidRefundModal(data, type) {
      this.typeVoidRefund = type;
      this.voidRefundData = {
        transaction_id: data.transaction_id,
        merchant: data.merchant,
        client_name: data.client_name,
        amount:
          parseFloat(data.sumref) > 0
            ? (data.amount - parseFloat(data.sumref)).toFixed(2)
            : parseFloat(data.amount.replace(/,/g, "")),
        settlement_date: data.settlement_date,
      };
      this.voidRefundModalOn = true;
    },
    closeVoidRefundModal() {
      this.voidRefundModalOn = false;
    },
    formatNumberWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async startMonthlyPaymentProcess() {
      /* This function will open the assignment new relationship modal
      in case the agent doesn't have an administrator-specialist relation.
      Once the agent has one it will open the MonthlyPaymentModal */
      if (
        this.moduleId == 20 &&
        /* 20: Connection */ this.client.status_connection == "HOLD"
      ) {
        await this.addPreloader();
        const params = { agent_id: this.client.advisor_connection_id };
        const { status: hasAdministratorSpecialistRelationship } =
          await ConnectionUsersRelationshipService.checkIfAgentHasRelationShip(
            params
          );
        if (!hasAdministratorSpecialistRelationship) {
          // Check if the agent has a specialist-administrator relationshop if not the modal for a new relationship will be opened before continuing with the monthly payment process
          this.preSelectedAgentId = this.client.advisor_connection_id;
          this.activeNewRelationshipModal = true;
          await this.removePreloader();
        } else {
          await this.openMonthlyPaymentModal();
          await this.removePreloader();
        }
      } else {
        await this.openMonthlyPaymentModal();
      }
    },
    async openMonthlyPaymentModal() {
      this.monthlyPaymentOn = true;
    },
    closeMonthlyPaymentModal() {
      this.monthlyPaymentOn = false;
    },

    async closeNewRelationshipModal(success = false) {
      this.activeNewRelationshipModal = false;
      if (success) await this.openMonthlyPaymentModal();
    },

    openChargeModal(item) {
      this.changeChargeId = item;
      this.titleAddCharge = "ADD CHARGE";
      this.addChargeOn = true;
    },
    closeChargeModal() {
      this.addChargeOn = false;
    },

    async getCountExpirationCardTab() {
      try {
        const params = {
          id: this.client.lead_id,
        };
        const data = await ClientsOptionsServices.getCountExpirationCardTab(
          params
        );

        if (data.status == 200) {
          this.countData =
            data.data[0].countExpiration > 99
              ? "+99"
              : data.data[0].countExpiration;
        }
        return this.countData;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async searchListCharges() {
      try {
        const params = {
          id: this.$route.params.idClient,
        };
        const data = await ClientsOptionsServices.getListCharges(params);

        this.listcharges = data.data;
        this.listcharges.map((index) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          if (index.amount != null) {
            const data = formatter.format(index.amount);
            index.amount = data.replace("$", "");
          }
        });

        return this.listcharges;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async searchListPayments() {
      try {
        const params = {
          id: this.$route.params.idClient,
        };
        const data = await ClientsOptionsServices.getListPayments(params);

        this.listpayments = data.data;
        this.listpayments.map((index) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          if (index.amount != null) {
            const data = formatter.format(index.amount);
            index.amount = data.replace("$", "");
          }
        });

        return this.listpayments;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    paymentCharge(item) {
      const data = {
        id: item.id,
        fee: item.fee,
        amount: item.amount,
        t_charge: item.t_charge,
        t_id: item.t_id,
        ncr_request_id: item.ncr_request_id,
      };
      this.titleAddCharge = "ADD CHARGE";
      this.dataCharge = data;
      this.paymentChargeOn = true;
    },

    closePaymentChargeModal() {
      this.paymentChargeOn = false;
    },

    changeCharge(item) {
      this.titleAddCharge = "EDIT CHARGE";
      this.changeChargeId = item;
      this.addChargeOn = true;
    },

    async deleteWithouPayment(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await ClientsOptionsServices.deleteWithoutPayment({
            id_transaction: id,
          });
          if (data.status === 200) {
            this.refresh();
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async checkoutPayment(item) {
      if (item.status_id != 4) return;
      this.addPreloader();
      try {
        const params = {
          id: item.transaction_id,
          merchant: item.merchant,
          is_inital: 0,
        };
        const response = await ClientDashboardService.checkoutPayment(params);
        await this.$refs.listPayments.refresh();
        await Promise.all([
          this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
            id: this.$route.params.idClient,
          }),
        ]);
        if (response.data == 2) {
          this.$swal({
            title: "Oops...",
            text: "The transaction was declined",
            icon: "warning",
          });
        }
        this.removePreloader();
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },

    async ApproveOrDeclineHeldTransaction(transaction, isAutoApprove = false) {
      try {
        this.addPreloader();
        const params = {
          transaction_id: transaction.transaction_id,
          action: "approve",
          user_id: this.currentUserId,
        };
        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);
        if (!isAutoApprove) {
          if (data.authorizeStatus == 8) {
            // used in src/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue  handleResponseSuccess
            await this.createCommissionChargesCed(transaction);
          }
          this.refresh();
          this.showGenericToast({
            position: "top-right",
            variant: data.variant,
            icon: data.icon,
            title: data.message,
            text: data.text,
          });
          this.refreshPaymentSchedule();
        }
      } catch (error) {
        this.showErrorSwal(error.response.data.message);
      } finally {
        this.removePreloader();
      }
    },

    async createCommissionChargesCed(transaction) {
      if (
        (this.moduleId === 22 ||
          this.moduleId === 23 ||
          this.moduleId === 20 ||
          this.moduleId === 26) &&
        (this.isAgent || this.isAssistantSupervisor || this.isSupervisor)
      ) {
        const data =
          await FinancialCommissionsService.createCommissionChargesCed({
            chargeId: transaction.type_charge,
            base_amount: transaction.amount,
            agent_id: this.currentUser.user_id,
            client_account: this.$route.params.idClient,
            module_id: this.moduleId,
          });
      }
    },
    refreshPaymentSchedule() {
      this.keyPaymentSchedule = Math.random();
    },
    async refresh() {
      this.removePreloader();

      this.$refs.listCharges.refresh();
      this.$refs.listPayments.refresh();
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
      ]);
    },

    ...mapMutations({
      setPaymentsTableStatus: "DebtSolutionClients/SET_UPDATE_PAYMENTS_TABLE",
    }),
    async connectionProcessWhenHoldPayMonthlyPayment({ amount }) {
      if (
        !(
          this.moduleId == 20 &&
          this.client.in_connection == "CONNECTION" &&
          this.client.status_connection == "HOLD"
        )
      ) {
        return;
      }
      try {
        if (this.ncrAndMonthlyPaymentComissionUsers && this.moduleId == 20) {
          await ClientsOptionsServices.generateComission({
            // Genarate commission for hold reconnection
            client_account_id: this.client.id,
            amount,
            type_commission: "PERCENTAGE",
            slug: "con-hold",
          });
        }

        await DashboardService.sendClientToConnection({
          client_id: this.client.client_id,
          client_account_id: this.client.id,
          in_connection: "CONNECTION",
          status_connection: "RECOVERED",
          comment: "",
          user_id: this.currentUserId,
        });
      } catch (err) {
        console.error(err);
      }
    },
    validateTypeTransactionChargeBack(data) {
      return (
        data.item.type_transaction_id === 15 &&
        data.item.method_transaction_id === null &&
        (data.item.modality_transaction_id === null ||
          data.item.modality_transaction_id === 5)
      );
    },
    validateTypeTransactionChargeBackReturn(data) {
      return (
        data.item.type_transaction_id === 15 &&
        data.item.method_transaction_id === null &&
        (data.item.modality_transaction_id === 6 ||
          data.item.modality_transaction_id === 7)
      );
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
  --shadow-light-color: #e8efffb4;
  --bg-dark-color: #17171a;
}
.title_lable {
  width: 200px;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> thead tr th {
  background-color: var(--primary-color) !important;
  color: white !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.border-card-light {
  /* box-shadow: 5px 5px #eaf0ff, 5px 5px #eaf0ff, 5px 5px #eaf0ff, 5px 5px #eaf0ff; */
  box-shadow: 0 0 8px rgb(189, 173, 173) !important;
  border-radius: 10px !important;
}
.border-card-dark {
  border-radius: 10px !important;
}
.button-style {
  background-color: #3f7afa !important;
  color: white !important;
}
.card-bg-dark {
  background-color: var(--bg-dark-color);
}
@media (max-width: 1366px) {
  .Cmoney {
    max-width: 150px;
  }
}
.pending-void-transaction {
  color: white;
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
