<template>
  <b-modal
    v-model="ownModal"
    title="Add Credit"
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <div>
      <b-row>
        <b-col>
          <b-card
            footer-class="p-1 d-flex justify-content-center"
            header="CLIENT"
            header-class="p-1 justify-content-center"
            :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
            header-tag="header"
            :footer="client.client_name"
            header-text-variant="white"
            footer-tag="footer"
            no-body
          />
        </b-col>
        <b-col
          ><b-card
            footer-class="p-1 d-flex justify-content-center"
            header="PROGRAM"
            header-class="p-1 justify-content-center"
            :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
            header-tag="header"
            :footer="client.program_name"
            header-text-variant="white"
            footer-tag="footer"
            no-body
        /></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Amount" label-for="amount">
            <money
              v-model="ammount"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
              }"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Specify" label-for="Specify">
            <b-form-input
              id="Specify"
              v-model="observation"
              type="text"
              placeholder="Enter description"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Reason of Credit" label-for="Reason of Credit">
            <b-textarea
              v-model="reason"
              placeholder="Enter the reason of Credit"
              class="d-flex justify-content-between w-100"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template #modal-footer>
      <b-button
        :disabled="spinner"
        variant="info"
        class="rounded"
        @click="saveStatus()"
        >Save<b-spinner v-if="spinner" class="ml-1" small />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ClientDashboard from "@/views/administration/views/clients/service/client.service";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";

export default {
  props: {
    client: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      ownModal: true,
      spinner: false,
      ammount: "",
      observation: "",
      reason: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  methods: {
    reformatToMoney(money) {
      const amount = money.toString();
      let number = "0.00";
      if (amount.indexOf(".") != -1) {
        let numberReplace = amount.replace(/,/gi, "");
        const numberSplit = numberReplace.split(".");
        number = numberSplit[0].split(/(?=(?:\d{3})+$)/).join(",");
        number = number + "." + numberSplit[1];
      } else {
        let numberReplace = amount.replace(/,/gi, "");
        number = numberReplace.split(/(?=(?:\d{3})+$)/).join(",");
        number = number + ".00";
      }
      return number;
    },
    // insertCreditPayment
    async saveStatus() {
      this.spinner = true;
      const data = {
        amount: this.reformatToMoney(this.ammount),
        user_id: this.currentUser.user_id,
        account: this.client.id,
        observation: this.observation,
        reason: this.reason,
      };
      try {
        const response = await ClientDashboardService.insertCreditPayment(data);
        if (response.status === 200) {
          this.$emit("refresh");
          this.showSuccessSwal("Success", "Transaction Finish");
        }

        this.spinner = false;
        this.close();
      } catch (error) {
        this.spinner = false;
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
